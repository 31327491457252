// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require.context('../images', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)

import '../stylesheets/application'

import 'jquery'
import 'bootstrap'

import 'paper/plugins/bootstrap-switch.min'
// import 'nouislider'
import 'moment'
import 'paper/plugins/bootstrap-selectpicker.min'
// import 'paper/plugins/bootstrap-datetimepicker.min'
// import 'paper/plugins/photo_swipe/photoswipe.min'
// import 'paper/plugins/photo_swipe/photoswipe-ui-default.min'
// import 'paper/plugins/photo_swipe/init-gallery'
import 'paper/paper-kit.min'
import 'paper/vertical-nav.min'

import 'lity/lity.min'

import $ from 'jquery';
global.$ = jQuery;
